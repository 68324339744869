<template>
  <b-container>
    <b-row class="mb-5 p-3" align-h="between">
      <b-col class="mb-5" lg="4" style="text-align: initial;">
        <b-row class="mb-5">
          <b-col><curva-title :title="$t('contact.info')" /></b-col>
        </b-row>
        <!--   Contact info     -->
        <b-row class="mb-3">
          <b-col lg="12">
            <p>
              <i class="text-warning las la-phone mr-2"></i> {{ $t('contact.complaints') }}
              <span class="text-warning">01001459180</span>
            </p>
          </b-col>
          <b-col lg="12">
            <p>
              <i class="text-warning las la-clock mr-2"></i> {{ $t('contact.workingHFrom') }}
              <span class="text-warning">{{ $t('contact.from') }}</span> {{ $t('contact.workingHTo') }}
              <span class="text-warning">{{ $t('contact.to') }}</span>
            </p>
          </b-col>
          <b-col lg="12"></b-col>
        </b-row>

        <!--   Branches     -->
        <b-row
          class="mb-4"
          v-for="(branch, key) in contacts.branches"
          :key="key"
        >
          <div class="city-box">{{ branch.name }}</div>
          <b-col lg="12">
            <p>
              <i class="text-warning las la-phone mr-2"></i> {{ branch.phone }}
            </p>
          </b-col>
          <b-col lg="12">
            <p style="text-align: justify; text-justify: inter-word">
              <i class="text-warning las la-map-marker-alt mr-2"></i>
              {{ branch.address }}
            </p>
          </b-col>
          <b-col lg="12">
            <a :href="branch.locationLink" target="_blank">
              <b-button class="curva-icon-btn">
                <i class="las la-map-marked-alt mr-2"></i> {{ $t('contact.location') }}</b-button
              ></a
            >
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="8">
        <!--   Contact form     -->
        <b-row class="justify-content-center contact-form-card">
          <b-col lg="7">
            <ValidationObserver v-slot="{ handleSubmit }">
              <b-form
                ref="form"
                @submit.prevent="handleSubmit(sendMsg)"
                class="curva-form curva-form-up mb-5"
              >
                <b-row class="mb-3"
                  ><b-col><curva-title :title="$t('forms.contactForm')" /></b-col
                ></b-row>
                <b-row>
                  <b-col lg="12" class="mb-1">
                    <input-form
                      :placeholder="$t('forms.name')"
                      v-model="info.name"
                      :validate="'required'"
                      :name="`name`"
                      custom-class="curva-control-input"
                    />
                  </b-col>
                  <b-col lg="12" class="mb-1">
                    <input-form
                      :placeholder="$t('forms.email')"
                      v-model="info.email"
                      :validate="'required|email'"
                      :name="`email`"
                      custom-class="curva-control-input"
                    />
                  </b-col>
                  <b-col lg="12" class="mb-1">
                    <input-form
                      :placeholder="$t('forms.phone')"
                      v-model="info.phone"
                      :validate="'required|digits:11'"
                      :name="`phone`"
                      custom-class="curva-control-input"
                    />
                  </b-col>
                  <b-col lg="12" class="mb-1">
                    <main-select
                      :placeholder="$t('forms.branch')"
                      v-model="info.branch"
                      :options="contacts.branches"
                      :validate="'required'"
                      :name="`branch`"
                      custom-class="curva-main-select"
                      label="name"
                      :reduce="(data) => data.name"
                    ></main-select>
                  </b-col>
                  <b-col lg="12" class="mb-1">
                    <b-form-group label-for="Message">
                      <ValidationProvider
                        name="FMessage"
                        ref="Message"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-textarea
                          :placeholder="$t('forms.msg')+'..'"
                          rows="2"
                          :validate="'required'"
                          v-model="info.message"
                          :class="[
                            'curva-textarea',
                            errors.length > 0 ? ' is-invalid' : '',
                          ]"
                        ></b-form-textarea>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12">
                    <b-button
                      class="curva-granola-btn small-rounded-btn"
                      type="submit"
                      block
                      >{{ $t('forms.send') }}</b-button
                    >
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import ContactServices from '@/modules/contact-us/services/contact-us.services'
export default {
  data () {
    return {
      contacts: {},
      info: {
        name: '',
        email: '',
        phone: '',
        branch: '',
        message: ''
      }
    }
  },
  methods: {
    getContacts () {
      ContactServices.getContactInfo().then((res) => {
        this.contacts = res.data.data
      })
    },
    sendMsg () {
      ContactServices.sendMessage(this.info).then((res) => {
        this.$refs.form.reset()
        core.showSnackbar('success', res.data.message)
      })
    }
  },
  created () {
    this.getContacts()
  },
  mounted () {
    core.index()
  }
}
</script>
<style>
.city-box {
  font-size: 24px;
  font-width: 400;
  padding: 5px 20px 5px 150px;
  margin-left: -150px;
  margin-bottom: 20px;
}
</style>
